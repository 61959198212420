import React from "react";

import AlertModal from "../components/Modal/AlertModal";
import ErrorModal from "../components/Modal/ErrorModal";

import * as Controller from "../Controller";




class Activate extends React.Component {

    constructor(props) {

        super(props);

        this.props = props;

        this.state = {
            message: null,
            error: null
        };

        this.hideAlertModal = this.hideAlertModal.bind(this);
    }



    componentDidMount() {

        const { id } = this.props.match.params;

        Controller.CheckVerification(id).then(
            (data) => {

                if (Boolean(data))
                    this.setState({ message: "Your account has been successfully activated" });
            },
            (error) => {
                this.setState({ error: error });
            }
        );
    }



    hideAlertModal() {

        this.props.history.push("/");
    }



    render() {

        if (this.state.message != null)
            return (<AlertModal title={"Info"} message={this.state.message} hideModal={this.hideAlertModal} />);
        else if (this.state.error != null)
            return (<ErrorModal title={"Warning"} error={this.state.error} hideModal={this.hideAlertModal} />);
        else
            return (<div className="loading"><i className="spinner fa-spin"></i></div>);           
    }
}

export default Activate;