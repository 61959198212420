import React, { useState, createContext, useEffect } from 'react';
import dotenv from 'dotenv'
dotenv.config();
export const AccountContext = createContext();



export const AccountContextProvider = props => {

    const [account, setAccount] = useState(JSON.parse(localStorage.getItem("account")));
    const appName = process.env.REACT_APP_NAME;


    useEffect(() => {

        setAccount(JSON.parse(localStorage.getItem("account")));
    }, [props.account])



    const doLogout = () => {

        localStorage.setItem('account', null);
        localStorage.removeItem('account');

        setAccount(null);
    }


    return (
        <AccountContext.Provider value={{ account, setAccount, doLogout }}>
            {props.children}
        </AccountContext.Provider>
    );
}