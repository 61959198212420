import React, { Component, Suspense } from 'react';
import { Route, Switch } from 'react-router';
import Cookies from 'universal-cookie';

import "./css/reset.css";
import "./css/w3.css";
import "./css/style.css";
import "./css/icofont.min.css";
import "./css/all.css";
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import $ from "jquery"
import * as Controller from "./Controller";

import Activate from './components/Activate';
import Promotions from './components/Promotions';

import { ConfigurationContextProvider } from './contexts/ConfigurationContext';
import { getConfiguration } from './helpers/configuration.helper';
import { AccountContextProvider } from './contexts/AccountContext';

import dotenv from 'dotenv'
dotenv.config();
const appName = process.env.REACT_APP_NAME;
const Layout = React.lazy(() => import('./components/Layout').then(module => ({ default: module.Layout })));
const Casino = React.lazy(() => import('./components/Casino/Casino').then(module => ({ default: module.Casino })));
const Account = React.lazy(() => import('./components/Account/Account'));

const Rules = React.lazy(() => import('./components/Rules/Rules'));




class App extends Component {

    constructor(props) {

        super(props);

        this.props = props;

        this.state = {
            loading: false,
            account: null,
            configuration: null,
            configurations: []
        };

        this.doLogin = this.doLogin.bind(this);
        this.HandleRisize = this.HandleRisize.bind(this);
    }



    componentDidMount() {

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let btag = params.get('btag');

        if (btag && btag.length > 3) {

            const cookies = new Cookies();

            cookies.set('referal', { btag: btag, date: new Date() }, { path: '/' });
        }

        this.GetWebSitePreference();

        window.addEventListener('resize', this.HandleRisize);
        $(".clearable").each(function () {

            const $inp = $(this).find("input:text"),
                $cle = $(this).find(".clearable__clear");

            $inp.on("input", function () {
                $cle.toggle(!!this.value);
            });

            $cle.on("touchstart click", function (e) {
                e.preventDefault();
                $inp.val("").trigger("input");
            });

        });
    }



    componentWillUnmount() {

        window.removeEventListener('resize', this.HandleRisize);
        clearInterval(this.balanceTmr);
    }



    HandleRisize() {

        if (this.state.configuration) {

            let config = getConfiguration(this.state.configurations);

            if (config.name !== this.state.configuration.name)
                this.setState({ configuration: config });
        }
    }



    doLogin(username, password) {

        if (username != null && password != null) {

            let self = this;
            this.setState({ loading: true });

            return new Promise(async function (resolve, reject) {

                Controller.Login(username, password).then(
                    (data) => {

                        if (data !== null) {

                            localStorage.setItem("account", JSON.stringify(data));
                            self.setState({ loading: false, account: data });

                            resolve(data);
                        }
                    },
                    (error) => {

                        self.setState({ loading: false });
                        reject(error);
                    }
                );
            });
        }
    }



    GetWebSitePreference() {

        Controller.GetWebSiteConfiguration().then(
            (data) => {

                this.setState({ configuration: getConfiguration(data), configurations: data });
            },
            (error) => {

                console.log(error);
            }
        );
    }



    GetRoute(section, id) {

        return (<Route key={id} exact path={"/" + section.path} render={(routeProps) => (<Casino {...routeProps} section={section} account={this.state.account} doLogin={this.doLogin} key={id} />)} />);
    }



    render() {

        if (this.state.configurations === null || this.state.configurations.length === 0)
            return (<div className="loading"><i className="spinner fa-spin" /></div>);
        else {

            let configuration = this.state.configuration;


            return (
                <Suspense fallback={<div className="loading"><i className="spinner fa-spin"></i></div>}>
                    <ConfigurationContextProvider configuration={configuration}>
                        <AccountContextProvider account={this.state.account}>
                            <Layout doLogin={this.doLogin} account={this.state.account}>
                                <Switch>
                                    {((document.location.pathname.split("/").length >= 3 &&
                                        (document.location.pathname.split("/")[1] == "" ||
                                            document.location.pathname.split("/")[1] == "casino" ||
                                            document.location.pathname.split("/")[1] == "livecasino" ||
                                            document.location.pathname.split("/")[1] == "virtual" ||
                                            document.location.pathname.split("/")[2].includes("search="))) ?
                                        (<Route path="/:gameType?/:category?/:categoryType?" render={(routeProps) => (<Casino {...routeProps} section={configuration.homepage} account={this.state.account} doLogin={this.doLogin} />)} />) :
                                        (<Route exact path="/" render={(routeProps) => (<Casino {...routeProps} section={configuration.homepage} account={this.state.account} doLogin={this.doLogin} />)} />))}
                                    {configuration.sections.map((section, index) => this.GetRoute(section, index))}
                                    <Route path='/profile' render={(routeProps) => (<Account {...routeProps} account={JSON.parse(localStorage.getItem("account"))} />)} />
                                    <Route exact path='/promotions' component={Promotions} {...this.props} />
                                    <Route path='/promotions/:name' component={Promotions} {...this.props} />
                                    <Route exact path='/activate/:id' component={Activate} {...this.props} />
                                    <Route path='/page/:page' component={Rules} {...this.props} />
                                    <Route exact path='/popup' render={() => (<div className="loading"><i className="spinner fa-spin" /></div>)} />
                                </Switch>
                            </Layout>
                        </AccountContextProvider>
                    </ConfigurationContextProvider>
                </Suspense>
            );
        }
    }
}

export default App;