import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import PromotionModal from "./Modal/PromotionModal";

import * as Controller from "../Controller";




class Promotions extends Component {

    constructor(props) {

        super(props);

        this.props = props;

        this.state = {

            detailMdl: false,
            promotions: null
        };
    }



    componentDidMount() {

        this.GetPromotions();
    }



    async GetPromotions() {

        let self = this;

        Controller.GetPromotions().then(
            (data) => {

                try {

                    const { name } = self.props.match.params;

                    data.forEach(function (item, index) {

                        data[index].id = index + 1;
                        data[index].action = index;


                        if (item.name.toLowerCase() === name)
                            self.setState({ banner: item.banner, description: item.description, detailMdl: true })


                        if (index === data.length - 1)
                            self.setState({ promotions: data });
                    });
                }
                catch { }
            },
            (error) => {

                this.setState({ error: error });
            });
    }



    DetailModal(banner) {

        return (

            <PromotionModal banner={this.state.banner} description={this.state.description} hideModal={() => { this.setState({ detailMdl: false }) }} />
        );
    }



    render() {

        const { t } = this.props;

        const promotions = this.state.promotions;
        let modal = this.state.detailMdl ? this.DetailModal() : null;

        return (
            <>
                {modal}

                <div className="games grid mw100 casino-box-shadow">
                    <div className="w100 game-list relative max-width search-casino-animation">
                        <div className="w100">
                            <div className="row" style={{ marginLeft: "0", marginRight: "0" }}>
                                {promotions != null ? promotions.map((promotion, index) => (
                                    <div key={"promotion" + index} className="col-lg-6 col-md-6 col-e-xs-12" style={{ paddingLeft: "0", paddingRight:"0" }}>
                                        <div className="promotion-box" style={{ marginLeft: "5px", marginRight: "5px" }}>
                                            <div>
                                                <img src={promotion.banner} className="img-responsive" style={{ cursor: "pointer" }} onClick={() => { this.setState({ banner: promotion.banner, description: promotion.description, detailMdl: true }) }} />
                                            </div>
                                            <div className="promotion-content-wrapper">
                                                <div className="pomotion-content-text">
                                                    <h6 className="title">{promotion.title}</h6>
                                                    <div className="text">{promotion.label}</div>
                                                </div>
                                                <div className="buttons-container">
                                                    <div className="buttons">
                                                        <button type="button" className="btn primary terms" onClick={() => { this.setState({ banner: promotion.banner, description: promotion.description, detailMdl: true }) }}>{t("DETAILS")}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </>);
    }
}

export default withTranslation() (Promotions);