import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import ReactHtmlParser from 'react-html-parser'; 



class PromotionModal extends Component {

    constructor(props) {

        super(props);

        this.props = props;

        this.modalRef = React.createRef();

        this.handleClickOutside = this.handleClickOutside.bind(this);
    }



    componentDidMount() {

        document.addEventListener('mousedown', this.handleClickOutside);
    }



    componentWillUnmount() {

        document.removeEventListener('mousedown', this.handleClickOutside);
    }



    handleClickOutside(event) {

        if (this.modalRef && this.modalRef.current != null && !this.modalRef.current.contains(event.target))
            this.props.hideModal()
    }



    render() {

        const { t } = this.props;

        return (
            <div className="modal modal-scroll" style={{ display: "block" }}>
                <div className="modal-dialog modal-xl w3-animate-top" ref={this.modalRef}>
                    <div className="modal-content-deposit">
                        <button type="button" className="close close-modal-xl" onClick={() => { this.props.hideModal() }}><i className="fas fa-times"></i></button>
                        <img className="img img-responsive image-cover" src={this.props.banner} />
                        <div className="modal-body-xl">
                            {ReactHtmlParser(this.props.description)}
                        </div>
                    </div>
                </div>
            </div>);
    };
}

export default withTranslation() (PromotionModal);